import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogPage = ({ data }) => {
	return (
		<Layout pageTitle="Blog">
			<PageHeading>
				<h1>Blog</h1>
				<p>
					Welcome to my blog about web development and everything else I may
					decide to post on here.
				</p>
				<hr />
			</PageHeading>
			<PostsList>
				{data.allMdx.edges.map((post) => (
					<div className="post-card" key={post.node.id}>
						<Link to={`/${post.node.slug}`}>
							{getImage(post.node.frontmatter.thumbnail) && (
								<div className="post-thumbnail">
									<GatsbyImage
										image={getImage(post.node.frontmatter.thumbnail)}
										alt={post.node.frontmatter.title}
									/>
								</div>
							)}
							<div className="post-meta">
								<h2>{post.node.frontmatter.title}</h2>
								<p className="post-info">
									{post.node.frontmatter.created} &#8226; {post.node.timeToRead}{" "}
									{parseInt(post.node.timeToRead) > 1 ? "Mins" : "Min"}
								</p>
								<p className="post-excerpt">{post.node.excerpt}</p>
							</div>
						</Link>
					</div>
				))}
			</PostsList>
		</Layout>
	);
};

const PageHeading = styled.div`
	width: 50%;
	margin: auto;
	hr {
		margin-top: 1rem;
		border: none;
		border-top: 3px solid var(--accent);
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

const PostsList = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: auto;
	padding-block: 2rem;
	.post-card {
		display: flex;
		border-radius: 0.5rem;
		padding: 0.5rem;
		margin-bottom: 0.3rem;
		transition: background 150ms;
		a {
			text-decoration: none;
			width: 100%;
			height: 100%;
			color: var(--fg);
			display: flex;
			flex-direction: row;
			align-items: center;
			.post-thumbnail {
				margin-right: 0.5rem;
				img {
					display: block;
					height: 100px;
					width: 100px;
					min-width: 100px;
					object-fit: cover;
					border-radius: 10px;
					overflow: hidden;
				}
			}
			.post-meta {
				display: flex;
				flex-direction: column;
			}
		}
		.post-info {
			margin-block: 0.3rem;
			color: var(--gray);
		}
		.post-excerpt {
			font-size: 1rem;
		}
		&:hover {
			background: var(--hoverBg);
		}
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 800px) {
		.post-card {
			a {
				.post-thumbnail {
					display: none;
				}
			}
		}
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

export const query = graphql`
	{
		allMdx(
			filter: { frontmatter: { published: { eq: true } } }
			sort: { fields: frontmatter___created, order: DESC }
		) {
			edges {
				node {
					frontmatter {
						title
						thumbnail {
							childImageSharp {
								gatsbyImageData(placeholder: DOMINANT_COLOR)
							}
						}
						created(formatString: "DD MMMM YYYY")
					}
					id
					slug
					timeToRead
					excerpt
				}
			}
		}
	}
`;

export default BlogPage;
